import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout displayFooter location={location}>
    <Helmet title="Comment ça marche" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>Comment ça marche</h1>
      <p>
        Esse id tempor enim nostrud sunt nulla incididunt esse esse. Et excepteur sit nostrud elit voluptate proident
        labore consectetur esse incididunt mollit. Anim laborum sint voluptate nisi incididunt non veniam. Proident
        irure sit est eiusmod consequat laboris labore. Commodo sit ea enim tempor fugiat qui amet. Amet sit Lorem ea
        elit est culpa id non mollit elit qui mollit. Nisi consequat laboris fugiat est dolor veniam cupidatat non anim
        id do reprehenderit.
      </p>
      <p>
        Qui sint exercitation qui elit culpa duis et. Eu qui mollit pariatur consectetur do officia enim incididunt enim
        excepteur. Ut labore mollit aliqua cupidatat velit consequat dolore voluptate ipsum veniam nostrud. Culpa culpa
        aute tempor ullamco duis cillum deserunt elit laborum ex dolor. Nulla nulla velit magna aute adipisicing
        consequat et eiusmod esse minim aliquip aliqua. Ullamco laborum minim reprehenderit proident cillum dolore nisi
        do magna anim velit. Amet ipsum elit duis ullamco voluptate consequat laboris sint ut qui aliqua ea quis.
      </p>
      <p>
        Deserunt in nisi ipsum ullamco quis deserunt proident sit quis sit sit voluptate sunt. Proident labore nostrud
        ipsum laboris commodo. Esse consequat dolor eiusmod ad pariatur. Proident velit dolor eu ut ut. Est duis ad aute
        elit deserunt duis ut ex cillum in.
      </p>
    </section>
  </Layout>
);
